<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="$can('create', 'faq')"
          color="success"
          @click="dialog = true"
          >NOVO</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table
          :headers="grid.headers"
          :items="filteredItems"
          :loading="grid.loading"
          loading-text="Carregando... aguarde"
          locale="pt"
          class="elevation-1"
        >
          <template v-slot:item.pergunta="{ item }">
            <span>{{ item.pergunta }}</span>
          </template>

          <template v-slot:item.resposta="{ item }">
            <span>{{ item.resposta }}</span>
          </template>

          <template v-slot:item.msgAtiva="{ item }">
            <span>{{ item.msgAtiva }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon
                v-if="$can('edit', 'faq')"
                color="green"
                class="mr-3"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="$can('delete', 'faq')"
                color="red"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer
          v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
          v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp"
          :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown"
          right
          class="elevation-1"
        >
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2"
              >Filtros</v-card-title
            >
            <v-text-field
              v-model="grid.filters.pergunta"
              label="Pergunta"
              dense
              solo
            ></v-text-field>
            <v-text-field
              v-model="grid.filters.resposta"
              label="Resposta"
              dense
              solo
            ></v-text-field>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isFormValid" autocomplete="off">
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Pergunta"
                      v-model="defaultUser.pergunta"
                      :rules="rules.pergunta"
                      required
                      counter
                      maxlength="300"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Resposta"
                      v-model="defaultUser.resposta"
                      :rules="rules.resposta"
                      required
                      counter
                      maxlength="1000"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="defaultUser.ativa"
                      :label="`${defaultUser.ativa ? 'Desativar' : 'Ativar'}`"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleItem">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pageLoadingMixin from "mixins/page-loading.mixin";
import Validations from "services/validations/validations.js";
import notificationMixin from "mixins/notification.mixin";
import configurations from "~/commons/configurations";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

export default {
  name: "RegisterFaq",
  mixins: [pageLoadingMixin, notificationMixin],
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  created() {
    this.getFaqs();
  },
  data: () => ({
    editedIndex: -1,
    indexUser: -1,
    dialog: false,
    isFormValid: undefined,
    isMobileFilterVisible: false,
    mobileFilter: null,
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Faq", to: "/admin/faq" },
    ],
    grid: {
      loading: false,
      filtered: false,
      filters: {
        pergunta: "",
        resposta: "",
      },
      headers: [
        { text: "Id", value: "id", align: " d-none" },
        {
          text: "Pergunta",
          value: "pergunta",
          align: "start",
          sortable: false,
        },
        { text: "Resposta", value: "resposta" },
        { text: "Ativa", value: "msgAtiva" },
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    grupos: [],
    cleanUser: {
      id: 0,
      pergunta: "",
      resposta: "",
      ativa: true,
      msgAtiva: true,
      nomeDeUsuario: "",
    },
    defaultUser: {
      id: 0,
      pergunta: "",
      resposta: "",
      ativa: true,
      msgAtiva: true,
      nomeDeUsuario: "",
    },
  }),
  methods: {
    getFaqs() {
      this.grid.loading = true;
      this.$api()
        .get(configurations.api.admin.faq.getFaqs)
        .then(({ data }) => {
          this.grid.data = data.faq.map((item) => {
            return {
              id: item.id,
              pergunta: item.pergunta,
              resposta: item.resposta,
              ativa: item.ativa,
              msgAtiva: item.ativa ? "Sim" : "Não",
            };
          });
          this.hidePageLoading();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos consultar a lista de Faqs.");
          }
          this.hidePageLoading();
        });
    },
    addFaq() {
      delete this.defaultUser.id;
      this.defaultUser.nomeDeUsuario = this.user.username;
      this.$api()
        .post(configurations.api.admin.faq.getRegisterFaqUri, this.defaultUser)
        .then(({ data }) => {
          if (data && data.faq) {
            data.faq.msgAtiva = data.faq.ativa ? "Sim" : "Não";
            this.grid.data.unshift(data.faq);
            this.notifySuccess("Faq cadastrado com sucesso.");
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos cadastrar o Faq.");
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexUser = this.editedIndex;
      this.defaultUser = Object.assign({}, item);
      this.showDialog();
    },
    showDialog() {
      this.dialog = true;
    },
    handleItem() {
      this.defaultUser.nomeDeUsuario = this.user.username;
      this.$refs.form.validate();
      if (this.isFormValid) {
        if (this.editedIndex > -1) {
          this.$api()
            .put(configurations.api.admin.faq.getUpdateFaqUri, this.defaultUser)
            .then(({ data }) => {
              if (data && data.faq) {
                Object.assign(this.grid.data[this.indexUser], {
                  id: data.faq.id,
                  pergunta: data.faq.pergunta,
                  resposta: data.faq.resposta,
                  ativa: data.faq.ativa,
                  msgAtiva: data.faq.ativa ? "Sim" : "Não",
                });
                this.notifySuccess("Informações atualizadas com sucesso.");
              }
            })
            .catch(({ response }) => {
              if (response && response.data) {
                this.notifyWarning(response.data.mensagem);
              } else {
                this.notifyWarning("Não conseguimos atualizar o Faq.");
              }
            });
        } else {
          this.addFaq();
        }
        this.close();
      } else this.notifyWarning("Preencha todos os campos obrigatórios");
    },
    close() {
      this.dialog = false;
      this.defaultUser = Object.assign({}, this.cleanUser);
      this.editedIndex = -1;
    },
    deleteItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      item.nomeDeUsuario = this.user.username;
      var result = confirm("Tem certeza de que deseja excluir este FAQ?");
      if (result) {
        this.$api()
          .post(configurations.api.admin.faq.getDeleteFaqUri, item)
          .then(({ data }) => {
            if (data && data.sucesso) {
              this.grid.data.splice(this.editedIndex, 1);
              this.notifySuccess("FAQ deletado com sucesso.");
            }
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.notifyWarning(response.data.mensagem);
            } else {
              this.notifyWarning("Não conseguimos deletar o FAQ.");
            }
          });
      }
    },
    filter() {
      if (this.grid.filters.pergunta || this.grid.filters.resposta) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/faq").length === 0) {
        this.$router.push({ path: "/login" });
      }
    }
  },
  computed: {
    ...postLoginGetters([USER]),
    filteredItems() {
      return this.grid.data.filter((i) => {
        return (
          i.pergunta
            .toLowerCase()
            .includes(this.grid.filters.pergunta.toLowerCase()) &&
          i.resposta
            .toLowerCase()
            .includes(this.grid.filters.resposta.toLowerCase())
        );
      });
    },
    rules() {
      return {
        pergunta: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 10),
        ],
        resposta: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 11),
          (value) => Validations.max(value, 1000),
        ],
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar Faq" : "Editar Faq";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}
</style>
